import React from 'react';

class ContactUs extends React.Component{
  constructor(props){
    super(props)
    
    this.state ={
      firstname: '',
      lastname: '',
      email: '',
      msgs: '',
    }

    this.submit = this.submit.bind(this);
  }

  componentDidMount(){

  }

  submit = ( ) =>{
    const isBrowser = typeof window !== 'undefined';
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/7724924/8a560b0a-7223-451c-91f4-cd3c250db4fb`;


    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: this.state.firstname,
        },
        {
          name: 'email',
          value: this.state.email
        },
        {
          name: 'lastname',
          value: this.state.lastname,
        },
        {
          name: 'message',
          value: this.state.msgs,
        },
      ],
      context: {
        pageUri,
        pageName,
      },
    };
 
    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
    })
    .then(res =>{ 
      this.setState({
        firstname: '',
        email: '',
        lastname: '',
        msgs: ''
      });
      res.json()})
    .catch(err => {
      this.setState({
        firstname: '',
        email: '',
        lastname: '',
        msgs: ''
      });
    });
  }

  render(){
    return (
      <>
        <div className="footer-contact-us">
          <div className="columns">
            <div className="column">
              <div className="mini-header" data-aos="slide-up">
                Contact Us<span class="blinking-cursor"></span>
              </div>
              <div className="heading-1" data-aos="slide-up">
                Let's talk MatSci AI
              </div>
              <div className="text-1" data-aos="slide-up">
              We are available 24*7 to discuss how Knowledge Graph enabled AI solutions can help your manufacturing business. Please let us know if you or your team is interested to reduce manufacturing cost or increase operational efficiency across the value chain. 
You can also directly reach us to <span className="email">info@matsci.ai</span>

              </div>
            </div>
            <div className="column" data-aos="slide-up">
            <div class="field">
              <label class="label-1">First Name</label>
              <div class="control">
                <input class="input" type="text" placeholder="Your first name" value={this.state.firstname} onChange={e => this.setState({ firstname : e.target.value})} />
              </div>
            </div>

            <div class="field">
              <label class="label-1">Last Name</label>
              <div class="control">
                <input class="input" type="text" placeholder="Your last name" value={this.state.lastname} onChange={e => this.setState({ lastname : e.target.value})} />
              </div>
            </div>

            <div class="field">
              <label class="label-1">Email</label>
              <div class="control">
                <input class="input" type="email" placeholder="Your Email" value={this.state.email} onChange={e => this.setState({ email : e.target.value})}/>
              </div>
            </div>

            <div class="field">
              <label class="label-1">Message</label>
              <div class="control">
                <textarea class="textarea" placeholder="Your Messege" value={this.state.msgs} onChange={e => this.setState({ msgs : e.target.value})}></textarea>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button class="button-1" onClick={this.submit}>SEND</button>
              </div>
            </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default ContactUs